<template>
  <div class="main__content">
    <div class="table__header_bar">
      <InputSearch isFilter @detectSearchValue="searchTableHandler" />
      <Button
        isControl
        iconName="plus"
        class="add__adm_cont"
        @click.native="createDartUserHandler"
      >
        {{ $t('sections.content_admin_to_register') }}
      </Button>
    </div>
    <Table
      v-if="getTableOfContentAccessAdmin.table"
      :content="getTableOfContentAccessAdmin.table.items"
      @tableHandler="tableHandler"
    />
    <v-skeleton-loader v-else type="table" />
  </div>
</template>

<script>
import Table from '@/elements/Table';
import { mapGetters } from 'vuex';

export default {
  components: {
    Table,
  },

  data() {
    return {};
  },

  created() {
    this.$store.dispatch('getTableOfContentAccessAdmin');
  },

  computed: {
    ...mapGetters(['getTableOfContentAccessAdmin']),
  },

  methods: {
    tableHandler() {
      this.$store.dispatch('getTableOfContentAccessAdmin');
    },

    searchTableHandler(val) {
      this.$store.commit('SET_TABLE_SORT_DATA', { search: val });
      this.$store.dispatch('getTableOfContentAccessAdmin');
    },

    createDartUserHandler() {
      this.$router.push({
        name: 'access-administrator_content-admin-create-user',
        params: { activity: 'create' },
      });
    },
  },
};
</script>

<style lang="sass" scoped>
.add__adm_cont
  width: 450px
  font-size: 16px
  @include s
    font-size: 14px
    width: auto
  @include xs
    font-size: 13px
    line-height: 16px
    text-align: center
</style>
